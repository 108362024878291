import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

class MailIcon extends Component {
  state = {
    hover: false,
  };
  render() {
    const color = this.props.color ? this.props.color : '#26327A';
    const hoverColor = this.props.hoverColor
      ? this.props.hoverColor
      : '#4565F2';

    return (
      <a
        href="mailto:info@thesixtynine.co.th"
        style={{
          display: `flex`,
          alignItems: `center`,
          cursor: `pointer`,
        }}
      >
        <svg
          onMouseEnter={() => this.setState({ hover: true })}
          onMouseLeave={() => this.setState({ hover: false })}
          width="100%"
          height="100%"
          viewBox="0 0 31 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.7599 0.293687C7.45778 0.293687 0.725098 6.95242 0.725098 15.1592C0.725098 23.3715 7.45778 30.0247 15.7599 30.0247C24.0676 30.0247 30.8003 23.366 30.8003 15.1592C30.7948 6.95242 24.0621 0.293687 15.7599 0.293687ZM22.537 8.55535L15.7544 13.8527L8.75552 8.55535H22.537ZM23.2302 21.7685H8.05119V10.7896L15.0445 16.0375C15.2331 16.1802 15.5104 16.2516 15.7322 16.2516C15.9596 16.2516 16.1592 16.1802 16.3478 16.0265L23.2191 10.6194V21.7685H23.2302Z"
            fill={this.state.hover ? hoverColor : color}
          />
        </svg>
      </a>
    );
  }
}

MailIcon.propTypes = {
  color: PropTypes.string,
  hoverColor: PropTypes.string,
};

export default MailIcon;
