import React, { Component } from 'react';
import styled from 'styled-components';

import FacebookIcon from '../components/FacebookIcon';
import Logo from '../components/Logo';
import MaintenanceImage from '../components/MaintenanceImage';
import MailIcon from '../components/MailIcon';
import SEO from '../components/seo';

import ArrowIcon from '../images/arrow-icon.svg';
import MaintenanceBackground from '../images/maintenance-bg.svg';
import MaintenanceMobileBackground from '../images/maintenance-mobile-bg.svg';

import Layout from '../components/Layout';

const VH100Box = styled.div`
  overflow: hidden;
  font-family: Roboto;
  position: relative;
  width: 100%;
  height: 100vh;
`;

const LogoBox = styled.div`
  width: 5%;
  height: auto;
  position: fixed;
  top: 5px;
  left: 5px;
  z-index: 1;
  padding-top: 3%;
  padding-left: 3%;
  @media (max-width: 425px) {
    position: fixed;
    left: 4%;
    top: 2.5%;
    width: 14%;
  }
`;

const PositionElementBox = styled.div`
  overflow: hidden;
  position: fixed;
  left: 40%;
  bottom: 1%;
  width: 50%;
  height: auto;
  @media (max-width: 425px) {
    overflow: hidden;
    margin-left: 27%;
    position: fixed;
    left: 10%;
    bottom: -12%;
    width: 70%;
    height: auto;
  }
  @media (max-width: 425px) and (min-height: 800px) {
    overflow: hidden;
    margin-left: 0%;
    position: fixed;
    left: 15%;
    bottom: -15%;
    width: 70%;
    height: auto;
  }
`;

const TextBox = styled.div`
  position: absolute;
  left: 3.5%;
  top: 0%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 28%;
  height: 100%;
  @media (max-width: 1024px) {
    position: fixed;
    left: 3.5%;
    top: 0%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 28%;
    height: 100%;
  }
  @media (max-width: 768px) {
    position: fixed;
    left: 4%;
    top: 0%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 28%;
    height: 100%;
  }
  @media (max-width: 425px) {
    padding-top: 3%;
    position: fixed;
    left: 7%;
    top: 16%;
    width: 50%;
    height: auto;
  }
`;

const MaintenanceMobileBackgroundBox = styled.div`
  position: fixed;
  overflow: hidden;
  opacity: 1;
  z-index: -10;
  background-image: none;
  width: 100vw;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: 100vw 100vh;
  @media (max-width: 425px) {
    background-image: url(${MaintenanceMobileBackground});
    opacity: 1;
    z-index: 0;
    overflow: hidden;
    position: fixed;
  }
`;

const MaintenaceBackgroundBox = styled.div`
  position: fixed;
  overflow: hidden;
  opacity: 1;
  z-index: 0;
  width: 100%;
  height: 100%;
  background: url(${MaintenanceBackground}) no-repeat center center fixed;
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: 425px) {
    overflow: hidden;
    opacity: 0;
    z-index: -10;
    position: fixed;
  }
`;

const Title = styled.div`
  font-style: normal;
  font-weight: 900;
  font-size: 4.7vw;
  line-height: 6.5vw;
  padding-bottom: 2vw;
  color: #26327a;
  @media (max-width: 425px) {
    font-style: normal;
    font-weight: bold;
    font-size: 10vw;
    line-height: 13vw;
    padding-bottom: 5vw;
    color: #26327a;
  }
`;

const Subtitle = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 1.5vw;
  line-height: 3vw;
  padding-bottom: 2vw;
  color: #26327a;
  @media (max-width: 425px) {
    font-style: normal;
    font-weight: 300;
    font-size: 4vw;
    line-height: 7vw;
    padding-bottom: 2vw;
    color: #26327a;
  }
`;

const FollowUs = styled.div`
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 1.5vw;
  line-height: 28px;
  color: #26327a;
  margin-bottom: 12%;
  @media (max-width: 425px) {
    display: none;
    font-style: normal;
    font-weight: bold;
    font-size: 4vw;
    line-height: 28px;
    color: #ffffff;
  }
`;

const FollowUsText = styled.div`
  padding-right: 20px;
`;

const ArrowBox = styled.div`
  display: flex;
  align-items: center;
  width: 10%;
  padding-right: 20px;
  @media (max-width: 425px) {
    display: none;
  }
`;

const FacebookIconBox = styled.div`
  width: 10%;
  margin-right: 10px;
  @media (max-width: 425px) {
    width: 8%;
    margin-right: 10px;
  }
`;

const MainIconBox = styled.div`
  width: 10%;
  @media (max-width: 425px) {
    width: 8%;
  }
`;

const SocialContainer = styled.div`
  display: flex;
  @media (max-width: 425px) {
    display: flex;
    justify-content: center;
  }
`;

const MobileSocialBox = styled.div`
  display: none;
  position: fixed;
  left: 10%;
  bottom: 2%;
  width: 80%;
  height: auto;
  @media (max-width: 425px) {
    display: block;
  }
`;

const MobileFollowUsText = styled.div`
  display: none;
  font-style: normal;
  font-weight: bold;
  font-size: 4vw;
  line-height: 28px;
  color: #ffffff;
  @media (max-width: 425px) {
    display: block;
    text-align: center;
    padding-bottom: 0.8vw;
  }
`;

class Maintenance extends Component {
  state = {
    mailHover: false,
    facebookHover: false,
  };
  render() {
    return (
      <>
        <SEO />
        <Layout>
          <MaintenanceMobileBackgroundBox>
            <VH100Box>
              <LogoBox>
                <Logo></Logo>
              </LogoBox>
              <MaintenaceBackgroundBox></MaintenaceBackgroundBox>
              <PositionElementBox>
                <VH100Box>
                  <MaintenanceImage></MaintenanceImage>
                </VH100Box>
              </PositionElementBox>
              <TextBox>
                <Title>We&apos;ll be back.</Title>
                <Subtitle>
                  We&apos;re currently working hard building for something
                  awesome and will be back soon.
                </Subtitle>
                <FollowUs>
                  <FollowUsText>Follow us</FollowUsText>
                  <ArrowBox>
                    <img src={ArrowIcon} />
                  </ArrowBox>
                  <FacebookIconBox>
                    <FacebookIcon></FacebookIcon>
                  </FacebookIconBox>
                  <MainIconBox>
                    <MailIcon></MailIcon>
                  </MainIconBox>
                </FollowUs>
              </TextBox>
              <MobileSocialBox>
                <MobileFollowUsText>Follow us</MobileFollowUsText>
                <SocialContainer>
                  <FacebookIconBox>
                    <FacebookIcon
                      color="#ffffff"
                      hoverColor="#ffffff"
                    ></FacebookIcon>
                  </FacebookIconBox>
                  <MainIconBox>
                    <MailIcon color="#ffffff" hoverColor="#ffffff"></MailIcon>
                  </MainIconBox>
                </SocialContainer>
              </MobileSocialBox>
            </VH100Box>
          </MaintenanceMobileBackgroundBox>
        </Layout>
      </>
    );
  }
}

export default Maintenance;
