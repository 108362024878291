import PropTypes from 'prop-types';
import React, { Component } from 'react';

class FacebookIcon extends Component {
  state = {
    hover: false,
  };

  render() {
    const color = this.props.color ? this.props.color : '#26327A';
    const hoverColor = this.props.hoverColor
      ? this.props.hoverColor
      : '#4565F2';

    return (
      <a
        href="https://www.facebook.com/the69com"
        style={{
          display: `flex`,
          alignItems: `center`,
          width: `100%`,
          cursor: `pointer`,
        }}
      >
        <svg
          onMouseEnter={() => this.setState({ hover: true })}
          onMouseLeave={() => this.setState({ hover: false })}
          width="100%"
          height="100%"
          viewBox="0 0 31 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.1347 0.744716C6.78814 0.744716 0 7.46933 0 15.7255C0 23.9872 6.78814 30.7063 15.1347 30.7063C23.4812 30.7063 30.2693 23.9872 30.2693 15.7255C30.2749 7.46933 23.4812 0.744716 15.1347 0.744716ZM19.3329 9.63218C19.3329 9.75844 19.2331 9.85725 19.1055 9.85725H17.2865C16.8372 9.85725 16.4768 10.2141 16.4768 10.6587V12.5471H19.0667C19.1998 12.5471 19.3052 12.6624 19.2941 12.7941L19.0667 15.451C19.0556 15.5663 18.9558 15.6596 18.8393 15.6596H16.4768V24.4867C16.4768 24.613 16.3769 24.7118 16.2494 24.7118H12.994C12.8664 24.7118 12.7666 24.613 12.7666 24.4867V15.7969V15.6541H11.1361C11.0085 15.6541 10.9087 15.5553 10.9087 15.4291V12.7722C10.9087 12.6459 11.0085 12.5471 11.1361 12.5471H12.7666V9.98351C12.7666 8.19943 14.2307 6.75021 16.0331 6.75021H19.1111C19.2386 6.75021 19.3384 6.84902 19.3384 6.97527V9.63218H19.3329Z"
            fill={this.state.hover ? hoverColor : color}
          />
        </svg>
      </a>
    );
  }
}

FacebookIcon.propTypes = {
  color: PropTypes.string,
  hoverColor: PropTypes.string,
};

export default FacebookIcon;
